@font-face {
  font-family: dripicons-v2;
  src: url("dripicons-v2.c676bec5.eot");
  src: url("dripicons-v2.c676bec5.eot#iefix") format("embedded-opentype"), url("dripicons-v2.e98d67d1.woff") format("woff"), url("dripicons-v2.f6e0990e.ttf") format("truetype"), url("dripicons-v2.ccb6d089.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: dripicons-v2 !important;
  font-style: normal !important;
  font-weight: normal !important;
}

[class^="dripicons-"]:before, [class*=" dripicons-"]:before {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: dripicons-v2 !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.dripicons-alarm:before {
  content: "a";
}

.dripicons-align-center:before {
  content: "b";
}

.dripicons-align-justify:before {
  content: "c";
}

.dripicons-align-left:before {
  content: "d";
}

.dripicons-align-right:before {
  content: "e";
}

.dripicons-anchor:before {
  content: "f";
}

.dripicons-archive:before {
  content: "g";
}

.dripicons-arrow-down:before {
  content: "h";
}

.dripicons-arrow-left:before {
  content: "i";
}

.dripicons-arrow-right:before {
  content: "j";
}

.dripicons-arrow-thin-down:before {
  content: "k";
}

.dripicons-arrow-thin-left:before {
  content: "l";
}

.dripicons-arrow-thin-right:before {
  content: "m";
}

.dripicons-arrow-thin-up:before {
  content: "n";
}

.dripicons-arrow-up:before {
  content: "o";
}

.dripicons-article:before {
  content: "p";
}

.dripicons-backspace:before {
  content: "q";
}

.dripicons-basket:before {
  content: "r";
}

.dripicons-basketball:before {
  content: "s";
}

.dripicons-battery-empty:before {
  content: "t";
}

.dripicons-battery-full:before {
  content: "u";
}

.dripicons-battery-low:before {
  content: "v";
}

.dripicons-battery-medium:before {
  content: "w";
}

.dripicons-bell:before {
  content: "x";
}

.dripicons-blog:before {
  content: "y";
}

.dripicons-bluetooth:before {
  content: "z";
}

.dripicons-bold:before {
  content: "A";
}

.dripicons-bookmark:before {
  content: "B";
}

.dripicons-bookmarks:before {
  content: "C";
}

.dripicons-box:before {
  content: "D";
}

.dripicons-briefcase:before {
  content: "E";
}

.dripicons-brightness-low:before {
  content: "F";
}

.dripicons-brightness-max:before {
  content: "G";
}

.dripicons-brightness-medium:before {
  content: "H";
}

.dripicons-broadcast:before {
  content: "I";
}

.dripicons-browser:before {
  content: "J";
}

.dripicons-browser-upload:before {
  content: "K";
}

.dripicons-brush:before {
  content: "L";
}

.dripicons-calendar:before {
  content: "M";
}

.dripicons-camcorder:before {
  content: "N";
}

.dripicons-camera:before {
  content: "O";
}

.dripicons-card:before {
  content: "P";
}

.dripicons-cart:before {
  content: "Q";
}

.dripicons-checklist:before {
  content: "R";
}

.dripicons-checkmark:before {
  content: "S";
}

.dripicons-chevron-down:before {
  content: "T";
}

.dripicons-chevron-left:before {
  content: "U";
}

.dripicons-chevron-right:before {
  content: "V";
}

.dripicons-chevron-up:before {
  content: "W";
}

.dripicons-clipboard:before {
  content: "X";
}

.dripicons-clock:before {
  content: "Y";
}

.dripicons-clockwise:before {
  content: "Z";
}

.dripicons-cloud:before {
  content: "0";
}

.dripicons-cloud-download:before {
  content: "1";
}

.dripicons-cloud-upload:before {
  content: "2";
}

.dripicons-code:before {
  content: "3";
}

.dripicons-contract:before {
  content: "4";
}

.dripicons-contract-2:before {
  content: "5";
}

.dripicons-conversation:before {
  content: "6";
}

.dripicons-copy:before {
  content: "7";
}

.dripicons-crop:before {
  content: "8";
}

.dripicons-cross:before {
  content: "9";
}

.dripicons-crosshair:before {
  content: "!";
}

.dripicons-cutlery:before {
  content: "\"";
}

.dripicons-device-desktop:before {
  content: "#";
}

.dripicons-device-mobile:before {
  content: "$";
}

.dripicons-device-tablet:before {
  content: "%";
}

.dripicons-direction:before {
  content: "&";
}

.dripicons-disc:before {
  content: "'";
}

.dripicons-document:before {
  content: "(";
}

.dripicons-document-delete:before {
  content: ")";
}

.dripicons-document-edit:before {
  content: "*";
}

.dripicons-document-new:before {
  content: "+";
}

.dripicons-document-remove:before {
  content: ",";
}

.dripicons-dot:before {
  content: "-";
}

.dripicons-dots-2:before {
  content: ".";
}

.dripicons-dots-3:before {
  content: "/";
}

.dripicons-download:before {
  content: ":";
}

.dripicons-duplicate:before {
  content: ";";
}

.dripicons-enter:before {
  content: "<";
}

.dripicons-exit:before {
  content: "=";
}

.dripicons-expand:before {
  content: ">";
}

.dripicons-expand-2:before {
  content: "?";
}

.dripicons-experiment:before {
  content: "@";
}

.dripicons-export:before {
  content: "[";
}

.dripicons-feed:before {
  content: "]";
}

.dripicons-flag:before {
  content: "^";
}

.dripicons-flashlight:before {
  content: "_";
}

.dripicons-folder:before {
  content: "`";
}

.dripicons-folder-open:before {
  content: "{";
}

.dripicons-forward:before {
  content: "|";
}

.dripicons-gaming:before {
  content: "}";
}

.dripicons-gear:before {
  content: "~";
}

.dripicons-graduation:before {
  content: "\\";
}

.dripicons-graph-bar:before {
  content: "";
}

.dripicons-graph-line:before {
  content: "";
}

.dripicons-graph-pie:before {
  content: "";
}

.dripicons-headset:before {
  content: "";
}

.dripicons-heart:before {
  content: "";
}

.dripicons-help:before {
  content: "";
}

.dripicons-home:before {
  content: "";
}

.dripicons-hourglass:before {
  content: "";
}

.dripicons-inbox:before {
  content: "";
}

.dripicons-information:before {
  content: "";
}

.dripicons-italic:before {
  content: "";
}

.dripicons-jewel:before {
  content: "";
}

.dripicons-lifting:before {
  content: "";
}

.dripicons-lightbulb:before {
  content: "";
}

.dripicons-link:before {
  content: "";
}

.dripicons-link-broken:before {
  content: "";
}

.dripicons-list:before {
  content: "";
}

.dripicons-loading:before {
  content: "";
}

.dripicons-location:before {
  content: "";
}

.dripicons-lock:before {
  content: "";
}

.dripicons-lock-open:before {
  content: "";
}

.dripicons-mail:before {
  content: "";
}

.dripicons-map:before {
  content: "";
}

.dripicons-media-loop:before {
  content: "";
}

.dripicons-media-next:before {
  content: "";
}

.dripicons-media-pause:before {
  content: "";
}

.dripicons-media-play:before {
  content: "";
}

.dripicons-media-previous:before {
  content: "";
}

.dripicons-media-record:before {
  content: "";
}

.dripicons-media-shuffle:before {
  content: "";
}

.dripicons-media-stop:before {
  content: "";
}

.dripicons-medical:before {
  content: "";
}

.dripicons-menu:before {
  content: "";
}

.dripicons-message:before {
  content: "";
}

.dripicons-meter:before {
  content: "";
}

.dripicons-microphone:before {
  content: "";
}

.dripicons-minus:before {
  content: "";
}

.dripicons-monitor:before {
  content: "";
}

.dripicons-move:before {
  content: "";
}

.dripicons-music:before {
  content: "";
}

.dripicons-network-1:before {
  content: "";
}

.dripicons-network-2:before {
  content: "";
}

.dripicons-network-3:before {
  content: "";
}

.dripicons-network-4:before {
  content: "";
}

.dripicons-network-5:before {
  content: "";
}

.dripicons-pamphlet:before {
  content: "";
}

.dripicons-paperclip:before {
  content: "";
}

.dripicons-pencil:before {
  content: "";
}

.dripicons-phone:before {
  content: "";
}

.dripicons-photo:before {
  content: "";
}

.dripicons-photo-group:before {
  content: "";
}

.dripicons-pill:before {
  content: "";
}

.dripicons-pin:before {
  content: "";
}

.dripicons-plus:before {
  content: "";
}

.dripicons-power:before {
  content: "";
}

.dripicons-preview:before {
  content: "";
}

.dripicons-print:before {
  content: "";
}

.dripicons-pulse:before {
  content: "";
}

.dripicons-question:before {
  content: "";
}

.dripicons-reply:before {
  content: "";
}

.dripicons-reply-all:before {
  content: "";
}

.dripicons-return:before {
  content: "";
}

.dripicons-retweet:before {
  content: "";
}

.dripicons-rocket:before {
  content: "";
}

.dripicons-scale:before {
  content: "";
}

.dripicons-search:before {
  content: "";
}

.dripicons-shopping-bag:before {
  content: "";
}

.dripicons-skip:before {
  content: "";
}

.dripicons-stack:before {
  content: "";
}

.dripicons-star:before {
  content: "";
}

.dripicons-stopwatch:before {
  content: "";
}

.dripicons-store:before {
  content: "";
}

.dripicons-suitcase:before {
  content: "";
}

.dripicons-swap:before {
  content: "";
}

.dripicons-tag:before {
  content: "";
}

.dripicons-tag-delete:before {
  content: "";
}

.dripicons-tags:before {
  content: "";
}

.dripicons-thumbs-down:before {
  content: "";
}

.dripicons-thumbs-up:before {
  content: "";
}

.dripicons-ticket:before {
  content: "";
}

.dripicons-time-reverse:before {
  content: "";
}

.dripicons-to-do:before {
  content: "";
}

.dripicons-toggles:before {
  content: "";
}

.dripicons-trash:before {
  content: "";
}

.dripicons-trophy:before {
  content: "";
}

.dripicons-upload:before {
  content: "";
}

.dripicons-user:before {
  content: "";
}

.dripicons-user-group:before {
  content: "";
}

.dripicons-user-id:before {
  content: "";
}

.dripicons-vibrate:before {
  content: "";
}

.dripicons-view-apps:before {
  content: "";
}

.dripicons-view-list:before {
  content: "";
}

.dripicons-view-list-large:before {
  content: "";
}

.dripicons-view-thumb:before {
  content: "";
}

.dripicons-volume-full:before {
  content: "";
}

.dripicons-volume-low:before {
  content: "";
}

.dripicons-volume-medium:before {
  content: "";
}

.dripicons-volume-off:before {
  content: "";
}

.dripicons-wallet:before {
  content: "";
}

.dripicons-warning:before {
  content: "";
}

.dripicons-web:before {
  content: "";
}

.dripicons-weight:before {
  content: "";
}

.dripicons-wifi:before {
  content: "";
}

.dripicons-wrong:before {
  content: "";
}

.dripicons-zoom-in:before {
  content: "";
}

.dripicons-zoom-out:before {
  content: "";
}

/*# sourceMappingURL=summary.052c661e.css.map */
